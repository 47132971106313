import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container } from 'react-bootstrap';

const email = 'info@promlabs.com';

const ImprintPage = () => (
  <Layout>
    <SEO title="Imprint" />
    <Container className="py-1">
      <h1 className="mt-5 mb-4">Imprint</h1>
      <h4 className="mt-4">Information in accordance with the German § 5 TMG:</h4>
      <p>PromLabs GmbH</p>
      <h4 className="mt-4">Postal address</h4>
      <p>
        Schönhauser Allee 168
        <br />
        10435 Berlin
        <br />
        Germany
        <br />
        <br />
        Email: <a href={`mailto:${email}`}>{email}</a>
      </p>
      <h4 className="mt-4">Represented by</h4>
      <p>Julius Volz (address as above)</p>
      <h4>Responsible for content in accordance with the German § 55 Abs. 2 RStV:</h4>
      <p>Julius Volz (address as above)</p>
      <h4 className="mt-4">Business registration data</h4>
      <p>
        <p>Registered via: District Court Charlottenburg (Amtsgericht Charlottenburg), Berlin</p>
        <p>Company Registration Number (Handelsregisternummer): HRB 218497 B</p>
        <p>EU VAT identification number: DE332537032</p>
      </p>
    </Container>
  </Layout>
);

export default ImprintPage;
